<template>
    <div class="bg-blue-50 rounded-sm p-4 my-8 border border-blue-500 border-solid shadow-md">
        <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-blue-600 feather feather-alert-triangle" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                    </path>
                    <line x1="12" y1="9" x2="12" y2="13"></line>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
            </div>
            <div class="ml-3">
                <h3 class="text-md font-bold dark:text-black">{{ text }}</h3>
                <div class="flex flex-col space-y-5 mt-2 text-sm text-gray-900 font-thin">
                    {{ description }}
                </div>
            </div>
            <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <span class="sr-only">Dismiss</span>
                    <button title="Закрыть" @click="handleHideShow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x rounded-full">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['update:hideShow'])
const { text, description, hideShow} = defineProps({
    text: {
        type: String,
    },
    description: {
        type: String,
    },
    hideShow: {
        type: Boolean,
    }
})

function handleHideShow() { 
    emit('update:hideShow', false)
}
</script>